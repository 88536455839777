import React from "react";
import data from "../yourdata";

const Skills = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="skills-container">
          <h1>Skills</h1>
          <div className="skills-grid">
            {data.skills.map((skill, index) => (
              <div
                className={`skill`}
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1.5fr 1fr",
                }}
              >
                <img
                  src={skill.img}
                  className={`${skill.spin ? "spinning" : ""}`}
                  alt="css"
                ></img>
                <p>{skill.para}</p>
                <p>{skill.yrs + "+ yrs"}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
