import React from "react";

const Card = ({ heading, paragraph, imgUrl, projectLink }) => {
  return (
    <div>
      <a href={projectLink} target="_blank" rel="noopener noreferrer">
        <div
          className="card"
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.2)),url(" +
              imgUrl +
              ")",
          }}
        ></div>
      </a>
      <div className="content">
        <h1 className="header">{heading}</h1>
        <p className="text">{paragraph}</p>
      </div>
    </div>
  );
};

export default Card;
