// Skills Icons
import htmlIcon from "./images/html.svg";
import react2Icon from "./images/react2.svg";
import dbIcon from "./images/db.svg";

// Social Icon
import githubIcon from "./images/github.svg";
import linkedinIcon from "./images/linkedin.svg";

// Work Images

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Nithin",
  headerTagline: [
    //Line 1 For Header
    "Building websites,",
    //Line 2 For Header
    "digital products,",
    //Line 3 For Header
    "and having fun!",
  ],
  //   Header Paragraph
  headerParagraph:
    "I am a passionate web developer with a knack for crafting engaging digital products. With a strong background in building websites and creating user-friendly experiences, I thrive on the challenges of the digital realm. Let's collaborate and bring your ideas to life!",
  //Contact Email
  contactEmail: "nithin.monni@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "King of the Pack", //Project Title - Add Your Project Title Here
      para: "King of the Pack is a pioneering digital business that offers non-crypto digital card collectibles with AI-generated art, providing collectors a captivating experience through innovative technology and intricate digital artworks.",
      //Project Image - Add Your Project Image Here
      imageSrc: "https://ik.imagekit.io/vgaqu63ro/King%20of%20the%20pack.png",
      //Project URL - Add Your Project Url Here
      url: "https://kingofthepack.vercel.app/",
    },
    {
      title: "Custom Music Player", //Project Title - Add Your Project Title Here
      para: "Developed a custom music player app that seamlessly integrates with the Spotify API, offering users an enhanced music streaming experience and personalized access to their Spotify library.",
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://ik.imagekit.io/vgaqu63ro/music%20player%20project%20image",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/MyManAy/t3-test",
    },
    {
      title: "Camp Venom", //Project Title - Add Your Project Title Here
      para: "For over three years, Camp Venom has been providing comprehensive Object-Oriented Python training to students in grades 3-7, fostering a generation of tech-savvy problem-solvers through interactive learning and engaging activities.",
      //Project Image - Add Your Project Image Here
      imageSrc: "https://ik.imagekit.io/vgaqu63ro/Camp%20Venom.png",
      //Project URL - Add Your Project Url Here
      url: "https://sites.google.com/view/free-coding-camp-venom/home?authuser=3",
    },
    {
      title: "Stock Analysis", //Project Title - Add Your Project Title Here
      para: "Personal stock analysis tool that uses webscraping to consolidate and filter data from earnings reports and insight rating sites, identifying promising stocks based on performance, insight scores, and growth percentages.",
      //Project Image - Add Your Project Image Here
      imageSrc: "https://ik.imagekit.io/vgaqu63ro/stock%20project%20image.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/MyManAy/stocks",
    },
    {
      title: "Discord Bots", //Project Title - Add Your Project Title Here
      para: "Created Discord bots that enhance server communities with features such as role memorization, comprehensive data tracking, and interactive multiplayer games, fostering an engaging and vibrant environment for participants.",
      //Project Image - Add Your Project Image Here
      imageSrc: "https://ik.imagekit.io/vgaqu63ro/discord%20bot%20image.jpeg",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/MyManAy/SprinklerJS",
    },
    {
      title: "Volunteer Search", //Project Title - Add Your Project Title Here
      para: "Prototyped the volunteer search application Hand-to-Hand, streamlining the process of discovering nearby volunteer opportunities based on distance, size, and theme, facilitating impactful community engagement.",
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://ik.imagekit.io/vgaqu63ro/volunteer%20project%20image.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/MyManAy/MyManAy.github.io-Volunteering",
    },
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "My name is Nithin Monni, and I'm currently a full-time PSEO student studying at the University of Minnesota, pursuing a computer science degree. My first experience with coding started at age 13, when I made silly video games in Python. Now, I specialize in full-stack web development, with many of my projects tailored around solving specific needs.",
  aboutParaTwo:
    "I believe that digitization is the future, and the faster we become comfortable with these online tools and teach others, the better. It's the motivation behind many of my projects, such as Camp Venom, which serves as the first step in creating a new generation of young programmers.",
  aboutParaThree:
    "You won't always find me coding to solve some deep, universal issue. Sometimes, I simply fire up my laptop, open my editor, and start coding for fun with no end goal. Regardless of why I'm programming, I always have fun and gain experience with new technologies and ideas.",
  aboutImage: "https://ik.imagekit.io/vgaqu63ro/about.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png",
      para: "Python",
      yrs: 4,
    },
    {
      img: htmlIcon,
      para: "HTML/CSS/JS",
      yrs: 3,
    },
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Typescript.svg/1200px-Typescript.png",
      para: "Typescript",
      yrs: 3,
    },
    {
      img: react2Icon,
      para: "React/React Native",
      yrs: 3,
      spin: true,
    },
    {
      img: dbIcon,
      para: "NoSQL & SQL DBs",
      yrs: 2,
    },
    {
      img: "https://cdn.worldvectorlogo.com/logos/next-js.svg",
      para: "Next.js",
      yrs: 1,
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Heading",
  promotionPara:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/MyManAy" },
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/nithin-monni-613b7925b/",
    },
  ],

  // End Contact Section ---------------
};

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
