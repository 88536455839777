import React from "react";
import data from "../yourdata";

const Header = () => {
  return (
    <div className="section" id="home">
      <div className="container" style={{}}>
        <div className="header-wrapper">
          <div className="heading-wrapper">
            <h1>
              {data.headerTagline[0]
                ? data.headerTagline[0]
                : "Building digital"}
            </h1>
            <h1>
              {" "}
              {data.headerTagline[1]
                ? data.headerTagline[1]
                : "products, brands"}
            </h1>
            <h1>
              {" "}
              {data.headerTagline[2] ? data.headerTagline[2] : "and experience"}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h2 style={{ fontSize: 24, marginRight: 24 }}>
                Hi, I'm {data.name}{" "}
                <span role="img" aria-label="Emoji">
                  👋
                </span>
              </h2>
              <div
                style={{
                  width: 200,
                  height: 200,
                  marginBottom: 35,
                }}
              >
                <img
                  style={{ placeSelf: "center" }}
                  src={"https://ik.imagekit.io/vgaqu63ro/intro.jpg"}
                  alt="about"
                ></img>
              </div>
            </div>
          </div>
          <p>{data.headerParagraph}</p>
          <a
            href={`mailto:${
              data.contactEmail ? data.contactEmail : "hello@chetanverma.com"
            }`}
            className="primary-btn"
          >
            CONNECT WITH ME
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
